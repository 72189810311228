import React, { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { NavLink } from 'react-router-dom';
import logo_image from '../images/inverted_image.png';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import PersonIcon from '@mui/icons-material/Person';
import InfoIcon from '@mui/icons-material/Info';
import GroupIcon from '@mui/icons-material/Group';

const pages = ['Home', 'Blogs', 'About', 'Contact Us'];
const aboutPages = [
  { name: 'About CEO', icon: <PersonIcon /> },
  { name: 'About Us', icon: <InfoIcon /> },
  { name: 'Team', icon: <GroupIcon /> }
];

function Header() {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const drawer = (
    <Box sx={{ textAlign: 'center', background: '#001012', height: '100%' }}>
      <NavLink to="/" style={{ textDecoration: 'none' }}>
        <Avatar
          alt="Logo"
          src={logo_image}
          variant="square"
          sx={{
            height: '120px',
            width: 'auto',
            margin: '20px auto',
          }}
        />
      </NavLink>
      <List>
        {pages.map((page) => (
          <NavLink
            key={page}
            to={`/${page.toLowerCase().replace(/ /g, '-')}`}
            style={{ textDecoration: 'none', color: 'white' }}
            onClick={handleDrawerToggle}
          >
            <ListItem component="button">
              <ListItemText primary={page} />
            </ListItem>
          </NavLink>
        ))}
      </List>
    </Box>
  );

  return (
    <AppBar position="sticky" sx={{ background: '#001012', color: 'white' }}>
      <Toolbar>
        <NavLink to="/" style={{ textDecoration: 'none' }}>
          <Avatar
            alt="Logo"
            src={logo_image}
            variant="square"
            sx={{
              height: '60px',
              width: 'auto',
              marginRight: '20px',
            }}
          />
        </NavLink>

        <Box sx={{ flexGrow: 1 }} />

        <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
          {pages.map((page) => (
            <React.Fragment key={page}>
              {page === 'About' ? (
                <>
                  <Button
                    sx={{ mx: 1, color: 'white' }}
                    aria-controls="about-menu"
                    aria-haspopup="true"
                    onClick={handleMenuClick}
                    endIcon={<KeyboardArrowDownRoundedIcon />}
                  >
                    {page}
                  </Button>
                  <Menu
                    id="about-menu"
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose}
                    MenuListProps={{
                      'aria-labelledby': 'about-button',
                    }}
                    PaperProps={{
                      sx: {
                        backgroundColor: '#001012',
                        color: 'white',
                        borderRadius: 2,
                        boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.2)',
                        minWidth: 200,
                      },
                    }}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                  >
                    {aboutPages.map((aboutPage) => (
                      <NavLink
                        key={aboutPage.name}
                        to={`/about/${aboutPage.name.toLowerCase().replace(/ /g, '-')}`}
                        style={{ textDecoration: 'none', color: 'inherit' }}
                      >
                        <MenuItem onClick={handleMenuClose} sx={{ display: 'flex', gap: 1 }}>
                          {aboutPage.icon}
                          {aboutPage.name}
                        </MenuItem>
                      </NavLink>
                    ))}
                  </Menu>
                </>
              ) : (
                <NavLink
                  to={`/${page.toLowerCase().replace(/ /g, '-')}`}
                  style={{ textDecoration: 'none' }}
                >
                  <Button sx={{ mx: 1, color: 'white' }}>{page}</Button>
                </NavLink>
              )}
            </React.Fragment>
          ))}
        </Box>

        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          onClick={handleDrawerToggle}
          sx={{ display: { xs: 'block', md: 'none' }, color: 'white' }}
        >
          <MenuIcon />
        </IconButton>
      </Toolbar>

      <Drawer
        anchor="left"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        sx={{ '&.MuiDrawer-paper': { boxSizing: 'border-box', width: 250 } }}
      >
        {drawer}
      </Drawer>
    </AppBar>
  );
}

export default Header;
