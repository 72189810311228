import React from 'react';
import { Grid, Box, Typography } from '@mui/material';
import bedbug_image from '../../images/rodent infestation.jpg';

const RodentService = () => {
    return (
        <Grid
            container
            spacing={2}
            sx={{
                marginTop: 12,
                backgroundColor: '#f5f5f5',
                alignItems: 'center',
                borderBottom: '2px dotted black',
                justifyContent: 'center', 
            }}
        >
            <Grid
                item
                xs={9}
                md={11}
                sx={{
                    textAlign: 'center', 
                    margin: 'auto',
                    position: 'relative',
                    paddingBottom: '12px',
                }}
            >
                <Typography
                    variant='h4'
                    sx={{
                        marginBottom: '12px',
                        paddingBottom: '4px',
                        position: 'relative',
                        zIndex: 1,
                        fontSize: { xs: '32px', md: '28px' },
                    }}
                >
                    RODENT CONTROL SERVICES
                </Typography>
                <Box
                    sx={{
                        position: 'absolute',
                        bottom: 0,
                        left: '50%',
                        transform: 'translateX(-50%)',
                        height: '2px',
                        width: '100%',
                        background: 'linear-gradient(to right, red 3cm, black 3cm)',
                    }}
                />
            </Grid>
            <Grid
                item
                xs={12}
                md={5}
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                    <img src={bedbug_image} alt="Sample" style={{ maxWidth: '80%', height: '400px', borderRadius: '6px' }} />
                </Box>
            </Grid>
            <Grid
                item
                xs={10}
                md={5}
                sx={{
                    margin: '0 auto',
                    textAlign: 'center',
                }}
            >
                <Typography 
                    sx={{
                        fontSize: { xs: '32px', md: '28px' },
                        paddingBottom: 1,
                    }}>
                     <strong>Pest Covers - House Mouse, Roof rats, Norway rat, Bandicoot.</strong>
                </Typography>

                <Typography
                    sx={{
                        fontSize: { xs: '24px', md: '28px' },
                        paddingBottom: 1,
                    }}>
    
                   
                    <br />
                    Rodents are very clever mammals. Their breeding rate is also high as one pair can multiply to 800 in a year. They infest, destroy, contaminate more than they feed, because of their continuous “GNOWING ACTION”. Rodent cause severe diseases like rat bite fever, Leptospirosis, Plague, Trichinosis, Salmonellosis and also food poisoning.
                </Typography>
            </Grid>
        </Grid>
    );
}

export default RodentService;
