import React, { useEffect } from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import backimage from '../../images/b2.png';
import Footer from '../footer';
import GlobalTeam from '../global';
import GetQuote from '../freequote';
import { Link } from 'react-router-dom';
import RodentService from './rodentcontrol_segment';
import Pestcover from './rodentpestcover';

const Rodent: React.FC = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Box>
            {/* Hero Section */}
            <Box
                sx={{
                    position: 'relative',
                    backgroundImage: `url(${backimage})`,
                    backgroundSize: 'cover',
                    height: '70vh',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    color: 'white',
                    '&::before': {
                        content: '""',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    },
                }}
            >
                <Grid container justifyContent="center">
                    <Grid
                        item
                        xs={10}
                        md={9}
                        sx={{
                            position: 'relative',
                            zIndex: 1,
                            textAlign: 'center',
                        }}>
                        <Typography
                            variant="h2"
                            sx={{
                                mb: 2,
                                fontSize: { xs: '48px', md: '40px' },
                                textAlign: 'center',
                                color: 'white',
                            }}>
                                 <strong>RODENT CONTROL SERVICES</strong> 
                        </Typography>

                        <Typography
                            variant="body1"
                            sx={{
                                color: 'white',
                                fontSize: { xs: '24px', md: '20px' },
                                textAlign: 'center',
                                mb: 3,
                            }}>
                            CPSC is an established pest control company situated in Kota, offering expert mouse, rat & rodent control services in Gurgaon and surrounding areas like Noida, Navi Gurgaon, Pune, etc. Our experts are available with just one call to help you get rid of these pests.
                        </Typography>

                        <Button
                            component={Link}
                            to="../freequote"
                            variant="contained"
                            sx={{
                                background: 'yellow',
                                color: 'black',
                                width: { xs: '180px', md: '200px' },
                                minHeight: { xs: '40px', md: '60px' },
                                margin: '0 auto',
                                padding: '10px',
                                borderRadius: '30px',
                                '&:hover': {
                                    background: 'lightyellow',
                                    fontWeight: 'bold',
                                },
                            }}
                        >
                            <Typography
                                variant="body1"
                                color={'black'}
                                sx={{ fontSize: { xs: '12px', md: '16px' } }}>
                                Request an Appointment
                            </Typography>
                        </Button>
                    </Grid>
                </Grid>
            </Box>

            {/* Content Sections */}
            <RodentService />
            <Pestcover />
            <GlobalTeam />
            <GetQuote />
        
        </Box>
    );
};

export default Rodent;
