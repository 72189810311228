import React, { useState, useEffect } from 'react';
import { Card, CardContent, Typography, IconButton, Grid } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

interface Testimonial {
    text: string;
    author: string;
}

const testimonials: Testimonial[] = [
    { text: "They were fast, efficient, and professional. Highly recommend!", author: "Jane Doe" },
    { text: "Excellent service! I will definitely use them again for any pest control.", author: "John Smith" },
    { text: "Great experience! The team was very knowledgeable and helpful.", author: "Alice Johnson" },
];

function Testimonials() {
    const [currentIndex, setCurrentIndex] = useState<number>(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex === testimonials.length - 1 ? 0 : prevIndex + 1));
        }, 3000); // Change testimonial every 3 seconds

        return () => clearInterval(interval); // Clean up interval on component unmount
    }, []);

    const handlePrevious = () => {
        setCurrentIndex((prevIndex) => (prevIndex === 0 ? testimonials.length - 1 : prevIndex - 1));
    };

    const handleNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex === testimonials.length - 1 ? 0 : prevIndex + 1));
    };

    return (
        <Grid 
          container
          sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            
            <Grid item xs={12} textAlign={'center'} sx={{ marginBottom: '1rem' }}>
                <Typography variant="h3" sx={{ fontWeight: 'bold' }}>
                    Testimonials
                </Typography>
            </Grid>

            <Grid
                item
                xs={12}
                md={12}
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: 3,
                    height: '100%',
                    position: 'relative',
                }}
            >
                <Card sx={{ width: '100%', maxWidth: '600px', backgroundColor: 'white', padding: 3 }}>
                    <CardContent
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: '100%',
                        }}
                    >
                        <Typography
                            variant="h5"
                            sx={{ textAlign: 'center', marginBottom: '1rem' }}
                        >
                            "{testimonials[currentIndex].text}"
                        </Typography>
                        <Typography
                            variant="body1"
                            sx={{ textAlign: 'center', fontStyle: 'italic' }}
                        >
                            - {testimonials[currentIndex].author}
                        </Typography>
                    </CardContent>
                </Card>

                <IconButton
                    onClick={handlePrevious}
                    aria-label="previous testimonial"
                    sx={{
                        position: 'absolute',
                        left: 0,
                        top: '50%',
                        transform: 'translateY(-50%)',
                        zIndex: 10,
                    }}
                >
                    <ArrowBackIosIcon />
                </IconButton>
                <IconButton
                    onClick={handleNext}
                    aria-label="next testimonial"
                    sx={{
                        position: 'absolute',
                        right: 0,
                        top: '50%',
                        transform: 'translateY(-50%)',
                        zIndex: 10,
                    }}
                >
                    <ArrowForwardIosIcon />
                </IconButton>
            </Grid>
        </Grid>
    );
}

export default Testimonials;
