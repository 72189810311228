import React from 'react';
import { Container, Typography, Box, Grid, Paper, Avatar, Button, IconButton, List, ListItem, ListItemText } from '@mui/material';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GitHubIcon from '@mui/icons-material/GitHub';
import InstagramIcon from '@mui/icons-material/Instagram';
import { styled } from '@mui/system';

const HeroSection = styled('div')({
  background: 'linear-gradient(135deg, #6a11cb 10%, #2575fc 100%)',
  color: '#fff',
  padding: '80px 0',
  textAlign: 'center',
  marginBottom: '40px',
});

const AvatarStyled = styled(Avatar)({
  width: '150px',
  height: '150px',
  margin: '0 auto',
  marginBottom: '20px',
});

const SocialIcons = styled(Box)({
  '& > *': {
    margin: '0 8px',
    color: '#fff',
  },
});

const Section = styled('div')({
  padding: '40px 0',
  textAlign: 'center',
});

const SectionTitle = styled(Typography)({
  marginBottom: '40px',
});

const PaperStyled = styled(Paper)({
  padding: '20px',
  minHeight: '200px',
  marginBottom: '20px',
  textAlign: 'left',
});

const TeamMember = styled(Grid)({
  marginBottom: '30px',
});

const TeamPage = () => {
  return (
    <Box>
      
      <HeroSection>
        <Container maxWidth="md">
          <Typography variant="h4" component="h1" gutterBottom>
            Our Team
          </Typography>
          <Typography variant="h6" gutterBottom>
            Meet the talented professionals who make our team outstanding.
          </Typography>
        </Container>
      </HeroSection>

      <Section color="#f0f0f0">
        <Container maxWidth="md">
          <SectionTitle variant="h3" gutterBottom>
            Meet the Team
          </SectionTitle>
          <Grid container spacing={3}>
            {/* Replace with your team members */}
            <TeamMember item xs={12} md={4}>
              <PaperStyled elevation={3}>
                <AvatarStyled alt="Jane Doe" src="/path/to/jane-profile-image.jpg" />
                <Typography variant="h5" gutterBottom>
                  Jane Doe
                </Typography>
                <Typography variant="h6" color="textSecondary" gutterBottom>
                  Project Manager
                </Typography>
                <Typography>
                  Jane has over 10 years of experience in project management and excels at delivering projects on time and within budget.
                </Typography>
                <SocialIcons>
                  <IconButton aria-label="LinkedIn" href="#">
                    <LinkedInIcon />
                  </IconButton>
                  <IconButton aria-label="GitHub" href="#">
                    <GitHubIcon />
                  </IconButton>
                  <IconButton aria-label="Instagram" href="#">
                    <InstagramIcon />
                  </IconButton>
                </SocialIcons>
              </PaperStyled>
            </TeamMember>
            <TeamMember item xs={12} md={4}>
              <PaperStyled elevation={3}>
                <AvatarStyled alt="John Smith" src="/path/to/john-profile-image.jpg" />
                <Typography variant="h5" gutterBottom>
                  John Smith
                </Typography>
                <Typography variant="h6" color="textSecondary" gutterBottom>
                  Lead Developer
                </Typography>
                <Typography>
                  John is a skilled developer with expertise in various technologies and a passion for creating innovative solutions.
                </Typography>
                <SocialIcons>
                  <IconButton aria-label="LinkedIn" href="#">
                    <LinkedInIcon />
                  </IconButton>
                  <IconButton aria-label="GitHub" href="#">
                    <GitHubIcon />
                  </IconButton>
                  <IconButton aria-label="Instagram" href="#">
                    <InstagramIcon />
                  </IconButton>
                </SocialIcons>
              </PaperStyled>
            </TeamMember>
            <TeamMember item xs={12} md={4}>
              <PaperStyled elevation={3}>
                <AvatarStyled alt="Emily Johnson" src="/path/to/emily-profile-image.jpg" />
                <Typography variant="h5" gutterBottom>
                  Emily Johnson
                </Typography>
                <Typography variant="h6" color="textSecondary" gutterBottom>
                  Marketing Specialist
                </Typography>
                <Typography>
                  Emily brings creativity and strategic thinking to our marketing efforts, ensuring our brand reaches the right audience.
                </Typography>
                <SocialIcons>
                  <IconButton aria-label="LinkedIn" href="#">
                    <LinkedInIcon />
                  </IconButton>
                  <IconButton aria-label="GitHub" href="#">
                    <GitHubIcon />
                  </IconButton>
                  <IconButton aria-label="Instagram" href="#">
                    <InstagramIcon />
                  </IconButton>
                </SocialIcons>
              </PaperStyled>
            </TeamMember>
          </Grid>
        </Container>
      </Section>

      {/* Contact Section */}
      <Section color="#f0f0f0">
        <Container maxWidth="md">
          <SectionTitle variant="h3" gutterBottom>
            Contact Us
          </SectionTitle>
          <PaperStyled elevation={3}>
            <Typography>
              <strong>Email:</strong>  support@chronicpestcontrolagencies.org
              <br />
              <strong>Phone:</strong> +91-9456486286
              <br />
              <strong>Location:</strong>  Mandsaur, Madhya Pradesh, India
            </Typography>
            <Button variant="contained" color="primary" href="mailto:contact@ourteam.com">
              Get in Touch
            </Button>
          </PaperStyled>
        </Container>
      </Section>
    </Box>
  );
};

export default TeamPage;
