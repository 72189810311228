import React from 'react';
import AppRoutes from './routes';
import { BrowserRouter as Router } from 'react-router-dom';
import Header from './components/header';
import { AuthProvider } from './AuthContext';
import Footer from './components/footer';


function App() {
  return (
    <AuthProvider>
    <Router>
    <Header/>
    <AppRoutes />
    <Footer/>
    </Router>
  </AuthProvider>
    
   
     
   
  );
}

export default App;
