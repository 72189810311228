import React from 'react';
import { Grid, Box, Typography, Button, TextField } from '@mui/material';
import bugimage from "../images/justin-lauria-Ap0alm8xpxw-unsplash.jpg";
import Testimonials from './Testimonials';

const GetQuote: React.FC = () => {
    return (
        <Box
            sx={{
                height: '90vh',
                backgroundImage: `url(${bugimage})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Grid container sx={{ height: '100%', width: '100%' }}>
                {/* Left side - Get Quote form with transparent background */}
                <Grid
                    item
                    xs={12}
                    md={6} // Adjusted to take half width
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        padding: 3,
                        height: '100%',
                        backgroundColor: 'rgba(255, 255, 255, 0.3)',
                        backdropFilter: 'blur(5px)',
                    }}
                >
                    <Typography
                        variant="h3"
                        sx={{
                            textAlign: 'center',
                            marginBottom: '.08rem',
                        }}
                    >
                        Get a Quote
                    </Typography>
                    <Typography
                        sx={{
                            fontSize: '20px',
                            textAlign: 'center',
                            marginBottom: '1.5rem',
                        }}
                    >
                        Want to discuss about a service or have a query? Drop a message or call us:
                    </Typography>
                    <form>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label="Name"
                                    name="name"
                                    variant="outlined"
                                    required
                                    sx={{
                                        marginBottom: '1rem',
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                borderColor: 'black',
                                                borderWidth: '2px',
                                            },
                                            '&.Mui-focused fieldset': {
                                                borderColor: 'black',
                                            },
                                        },
                                        '& .MuiInputLabel-root': {
                                            color: 'black',
                                            fontWeight: 'bold',
                                        },
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label="Email"
                                    name="email"
                                    variant="outlined"
                                    type="email"
                                    required
                                    sx={{
                                        marginBottom: '1rem',
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                borderColor: 'black',
                                                borderWidth: '2px',
                                            },
                                            '&.Mui-focused fieldset': {
                                                borderColor: 'black',
                                            },
                                        },
                                        '& .MuiInputLabel-root': {
                                            color: 'black',
                                            fontWeight: 'bold',
                                        },
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Phone"
                                    name="phone"
                                    variant="outlined"
                                    required
                                    sx={{
                                        marginBottom: '1rem',
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                borderColor: 'black',
                                                borderWidth: '2px',
                                            },
                                            '&.Mui-focused fieldset': {
                                                borderColor: 'black',
                                            },
                                        },
                                        '& .MuiInputLabel-root': {
                                            color: 'black',
                                            fontWeight: 'bold',
                                        },
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Message"
                                    name="message"
                                    variant="outlined"
                                    multiline
                                    rows={4}
                                    required
                                    sx={{
                                        marginBottom: '1rem',
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                borderColor: 'black',
                                                borderWidth: '2px',
                                            },
                                            '&.Mui-focused fieldset': {
                                                borderColor: 'black',
                                            },
                                        },
                                        '& .MuiInputLabel-root': {
                                            color: 'black',
                                            fontWeight: 'bold',
                                        },
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sx={{ textAlign: 'center' }}>
                                <Button 
                                  type="submit" 
                                  variant="contained" 
                                  color="primary" 
                                  sx={{ padding: '0.75rem 2rem' }}>
                                    Submit
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </Grid>

                {/* Right side - Testimonials */}
                <Grid item xs={12} md={6}> {/* Adjusted to take half width */}
                    <Testimonials />
                </Grid>
            </Grid>
        </Box>
    );
}

export default GetQuote;
