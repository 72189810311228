import { useEffect, useState } from "react";
import { Typography, Card, CardContent, Grid, Button } from "@mui/material";
import bugimage from "../images/carimage1.webp";

// Define the type for the blog data (including the 'id')
interface BlogData {
    id: number;
    blogTitle: string;
    blogSubtitle: string;
}

function Mainpageblog() {
    const [blogs, setBlogs] = useState<BlogData[]>([]);

    // Fetch blog data from the API
    useEffect(() => {
        const fetchBlogs = async () => {
            try {
                const response = await fetch("https://cpca-api.midastix.com/blog");
                const data: BlogData[] = await response.json();
                
                const sortedBlogs = data.sort((a, b) => a.id - b.id).slice(0, 2);
                setBlogs(sortedBlogs);
            } catch (error) {
                console.error("Error fetching blog data:", error);
            }
        };

        fetchBlogs();
    }, []);

    return (
        <Grid
            container
            sx={{
                marginTop: 15,
                position: 'relative',
                backgroundImage: `url(${bugimage})`,
                backgroundSize: 'cover',
                height: '60vh',
                width: "100%",
                display: 'flex',
                justifyContent: 'center',
                color: 'white',
                '&::before': {
                    content: '""',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.66)',
                    zIndex: 1,
                },
            }}
        >
            {/* Main content + cards as siblings, side by side */}
            <Grid
                container
                sx={{
                    position: 'relative',
                    zIndex: 2,
                    display: 'flex',
                    justifyContent: 'space-around', // Distribute space around items
                    alignItems: 'center',
                    width: '100%',
                    padding: '2rem',
                }}
            >
                {/* Heading Section */}
                <Grid
                    item
                    xs={12}
                    md={4}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        textAlign: 'center',
                    }}
                >
                    <Typography variant="h3" fontWeight={400} sx={{ fontSize: { xs: "30px", md: "36px" } }}>
                        Learn More about the things Infesting your home
                    </Typography>
                    <Typography variant="h4" fontWeight={300} sx={{ fontSize: { xs: "20px", md: "24px" } }}>
                        Residential Pest Control
                    </Typography>
                    {/* Read More Button */}
                    <Button
                        variant="contained"
                        sx={{
                            marginTop: 2,
                            backgroundColor: "#0bbcc9",
                            color: "white",
                            '&:hover': {
                                backgroundColor: "darkred",
                            },
                        }}
                    >
                        Read More
                    </Button>
                </Grid>

                {/* Render cards based on the blog data */}
                {blogs.map((blog) => (
                    <Grid item xs={12} md={3} key={blog.id}>
                        <Card
                            sx={{
                                width: "100%",
                                height: "180px",
                                borderTop:'solid #0bbcc9 4px',
                                backgroundColor: "white",
                                borderRadius: "8px",
                                boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-between",
                                alignItems: "center",
                                padding: "1rem",
                            }}
                        >
                            <CardContent>
                                <Typography variant="h5" fontWeight="bold" textAlign="center">
                                    {blog.blogTitle}
                                </Typography>
                                <Typography textAlign="center">{blog.blogSubtitle}</Typography>
                            </CardContent>
                            <Button
                                variant="outlined"
                                sx={{
                                    alignSelf: "flex-end",
                                    borderColor: "#0bbcc9",
                                    color: "#0bbcc9",
                                    borderWidth: "1px",
                                    padding: "0.3rem 1.2rem",
                                    marginTop: "auto", 
                                    "&:hover": {
                                        borderColor: "darkred",
                                        color: "darkred",
                                    },
                                }}
                            >
                                Learn More
                            </Button>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Grid>
    );
}

export default Mainpageblog;
