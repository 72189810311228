import React from 'react';
import { Grid, Box, Typography } from '@mui/material';
import bedbug_image from '../../images/Complete-Life-Cycle-Of-Bed-Bugs.jpg'

const BedBugControl = () => {


  return (
    <Grid container spacing={2} sx={{ marginTop: 12, backgroundColor: '#f5f5f5', alignItems: 'center' }}>
      <Grid item md={11} xs={11} sx={{ textAlign: 'left', margin: 'auto', position: 'relative', paddingBottom: '12px' }}>
        <Typography variant='h4' sx={{ marginBottom: '12px', paddingBottom: '4px', position: 'relative', zIndex: 1, fontSize: { xs: '20px', sm: '28px' } }}>
          BED BUG CONTROL SERVICES
        </Typography>
        <Box sx={{
          content: '""',
          position: 'absolute',
          bottom: 0,
          left: 0,
          height: '2px',
          width: '100%',
          background: 'linear-gradient(to right, red 3cm, black 3cm)'
        }} />
      </Grid>
      <Grid item xs={12} md={6}>
        <Box display="flex" justifyContent="center" alignItems="center" height="100%">
          <img src={bedbug_image} alt="Sample" style={{ maxWidth: '95%', height: '300px', borderRadius: '6px' }} />
        </Box>
      </Grid>
      <Grid item md={5} xs={10} sx={{ margin: '0 auto' }}>
        <Typography
          variant='h3'
          sx={{
            fontSize: { xs: '24px', md: '32px' },
            fontWeight: 'bold',
            marginBottom: 2
          }}>
          Introduction to Bed Bug
        </Typography>
        <Typography
          sx={{
            fontSize: { xs: '10px', md: '16px', sm: '24px' },
            paddingBottom: 5
          }}>
          Bed bugs (Cimex lectularius) are small, parasitic insects that feed on the blood of humans and animals. They are reddish-brown in color, wingless, and about the size of an apple seed (4-5 mm long). Bed bugs have been known to infest human dwellings for thousands of years, and their presence has become increasingly common in recent years due to various factors such as increased travel and pesticide resistance. Although they are not known to transmit diseases, bed bugs can cause significant discomfort, anxiety, and sleep disturbances due to their bites and the psychological stress of living with an infestation.

          Bed bugs are nocturnal creatures, feeding mainly at night when their hosts are asleep. They typically hide in cracks, crevices, and furniture during the day, making them difficult to detect until the infestation is severe. Their ability to go long periods without feeding (up to several months) and their high reproductive rate contribute to their persistence in homes, hotels, hospitals, and other places where people gather.


        </Typography>
      </Grid>
    </Grid>
  );
}

export default BedBugControl;
