import React from 'react';
import { Container, Typography, Box, Grid, Paper, Button, IconButton } from '@mui/material';
import { styled, keyframes } from '@mui/system';
import PestControlIcon from '@mui/icons-material/PestControl';

import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';


const HeroSection = styled('div')({
  background: 'linear-gradient(135deg, #ff7e5f 10%, #feb47b 100%)',
  color: '#fff',
  padding: '80px 0',
  textAlign: 'center',
  marginBottom: '40px',
});

const bounceAnimation = keyframes`
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
`;

const IconStyled = styled(PestControlIcon)({
  fontSize: '50px',
  animation: `${bounceAnimation} 2s infinite`,
});

const Section = styled('div')({
  padding: '40px 0',
  textAlign: 'center',
});

const SectionTitle = styled(Typography)({
  marginBottom: '40px',
});

const PaperStyled = styled(Paper)({
  padding: '20px',
  minHeight: '200px',
  marginBottom: '20px',
  textAlign: 'left',
});

const ServicesItem = styled(Grid)({
  marginBottom: '30px',
});

const AboutSection = styled(Box)({
  padding: '40px 0',
  backgroundColor: '#f0f0f0',
  textAlign: 'center',
});

const AboutPaper = styled(Paper)({
  padding: '20px',
  maxWidth: '800px',
  margin: '0 auto',
  animation: 'fadeIn 2s ease-in-out',
  '@keyframes fadeIn': {
    '0%': {
      opacity: 0,
    },
    '100%': {
      opacity: 1,
    },
  },
});

const CompanyPage = () => {
  return (
    <Box>
      <HeroSection>
        <Container maxWidth="md">
          <IconStyled />
          <Typography variant="h4" component="h1" gutterBottom>
            Your Trusted Pest Control Company
          </Typography>
          <Typography variant="h6" gutterBottom>
            Safe, Effective, and Eco-Friendly Solutions
          </Typography>
        </Container>
      </HeroSection>

      <Section>
        <Container maxWidth="md">
          <SectionTitle variant="h3" gutterBottom>
            Our Services
          </SectionTitle>
          <Grid container spacing={3}>
            <ServicesItem item xs={12} md={4}>
              <PaperStyled elevation={3}>
                <IconButton aria-label="Pest Control" color="primary">
                  <PestControlIcon style={{ fontSize: '40px' }} />
                </IconButton>
                <Typography variant="h5" gutterBottom>
                  Residential Pest Control
                </Typography>
                <Typography>
                  Keep your home safe and pest-free with our comprehensive residential pest control services.
                </Typography>
              </PaperStyled>
            </ServicesItem>
            <ServicesItem item xs={12} md={4}>
              <PaperStyled elevation={3}>
                <IconButton aria-label="Eco-Friendly" color="primary">
                <VerifiedUserIcon style={{ fontSize: '40px' }} />
                </IconButton>
                <Typography variant="h5" gutterBottom>
                  Eco-Friendly Solutions
                </Typography>
                <Typography>
                  Our eco-friendly pest control solutions ensure the safety of your family and the environment.
                </Typography>
              </PaperStyled>
            </ServicesItem>
            <ServicesItem item xs={12} md={4}>
              <PaperStyled elevation={3}>
                <IconButton aria-label="Commercial" color="primary">
                  <VerifiedUserIcon style={{ fontSize: '40px' }} />
                </IconButton>
                <Typography variant="h5" gutterBottom>
                  Commercial Pest Control
                </Typography>
                <Typography>
                  Protect your business with our reliable and effective commercial pest control services.
                </Typography>
              </PaperStyled>
            </ServicesItem>
          </Grid>
        </Container>
      </Section>

      <AboutSection>
        <Container maxWidth="md">
          <SectionTitle variant="h3" gutterBottom>
            About Us
          </SectionTitle>
          <AboutPaper elevation={3}>
            <Typography variant="h6" gutterBottom>
              Who We Are
            </Typography>
            <Typography>
              We are a dedicated team of pest control experts with over 20 years of experience. Our mission is to provide safe, effective, and eco-friendly pest control solutions to our customers.
            </Typography>
            <Typography variant="h6" gutterBottom sx={{mt:2}}>
              Our Mission
            </Typography>
            <Typography>
              Our mission is to protect your home and business from pests using environmentally responsible methods. We strive to deliver the highest quality of service and ensure your satisfaction.
            </Typography>
          </AboutPaper>
        </Container>
      </AboutSection>

      <Section>
        <Container maxWidth="md">
          <SectionTitle variant="h3" gutterBottom>
            Contact Us
          </SectionTitle>
          <PaperStyled elevation={3}>
            <Typography>
              <strong>Email:</strong>  support@chronicpestcontrolagencies.org
              <br />
              <strong>Phone:</strong> +91-9456486286
              <br />
              <strong>Location:</strong>  Mandsaur, Madhya Pradesh, India
            </Typography>
            <Button variant="contained" color="primary" href="mailto:contact@pestcontrolcompany.com" sx={{mt:2}}>
              Get in Touch
            </Button>
          </PaperStyled>
        </Container>
      </Section>
    </Box>
  );
};

export default CompanyPage;
