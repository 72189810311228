// src/components/SubmitComment.tsx

import React, { useState } from 'react';
import { Grid, Box, Typography, TextField, Button } from '@mui/material';
import { Rating } from '@mui/material';

const SubmitComment: React.FC = () => {
    const [comment, setComment] = useState<string>('');
    const [rating, setRating] = useState<number | null>(null);

    const handleCommentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setComment(event.target.value);
    };

    const handleRatingChange = (event: React.SyntheticEvent, newRating: number | null) => {
        setRating(newRating);
    };

    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        console.log({ comment, rating });
        setComment('');  // Clear the comment
        setRating(null); // Reset the rating
    };

    return (
        <Box sx={styles.container}>
            <Typography variant="h4" sx={styles.header}>
                Submit Your Comment
            </Typography>
            <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            label="Your Comment"
                            multiline
                            rows={4}
                            fullWidth
                            variant="outlined"
                            value={comment}
                            onChange={handleCommentChange}
                            required
                            sx={styles.textField}
                        />
                    </Grid>
                    <Grid item xs={12} sx={{ textAlign: 'center' }}>
                        <Typography variant="h6">Rate Us</Typography>
                        <Rating
                            name="rating"
                            value={rating}
                            onChange={handleRatingChange}
                            size="large"
                            sx={styles.rating}
                        />
                    </Grid>
                    <Grid item xs={12} sx={{ textAlign: 'center' }}>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            sx={styles.submitButton}
                        >
                            Submit
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </Box>
    );
};

export default SubmitComment;

const styles = {
    container: {
        maxWidth: '600px',
        margin: '0 auto',
        padding: '2rem',
        backgroundColor: 'white',
        borderRadius: '8px',
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
    },
    header: {
        textAlign: 'center',
        marginBottom: '1.5rem',
        fontWeight: 'bold',
    },
    textField: {
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'black',
                borderWidth: '2px',
            },
            '&.Mui-focused fieldset': {
                borderColor: 'black',
            },
        },
        '& .MuiInputLabel-root': {
            color: 'black',
            fontWeight: 'bold',
        },
    },
    rating: {
        marginTop: '1rem',
    },
    submitButton: {
        padding: '0.75rem 2rem',
        fontSize: '1rem',
    },
};
